import { useIsDevCycleInitialized, withDevCycleProvider } from "@devcycle/react-client-sdk";
import "@fontsource-variable/inter/wght.css";
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLocation,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";

import { AuthProvider } from "~/components/auth-context";
import { useHealthcheck } from "~/hooks/useHealthcheck";
import "./tailwind.css";

const queryClient = new QueryClient();

export function Layout({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();

  return (
    <QueryClientProvider client={queryClient}>
      <html lang="en">
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="color-scheme" content="light" />
          <meta name="theme-color" media="(prefers-color-scheme: light)" content="#F8FAFC" />
          <meta name="commit-hash" content={import.meta.env.VITE_COMMIT_HASH} />
          <meta name="author" content="The Worship Initiative" />

          <link rel="canonical" href={new URL(pathname, import.meta.env.VITE_PUBLIC_URL).toString()} />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"></link>
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"></link>
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"></link>
          <link rel="manifest" href="/site.webmanifest"></link>
          <Meta />
          <Links />
        </head>
        <body>
          {children}
          <ScrollRestoration />
          <Scripts />
          <Toaster richColors />
        </body>
      </html>
    </QueryClientProvider>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  if (isRouteErrorResponse(error)) {
    return (
      <div>
        <h1>
          {error.status} {error.statusText}
        </h1>
        <p>{error.data}</p>
      </div>
    );
  } else if (error instanceof Error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error.message}</p>
        <p>The stack trace is:</p>
        <pre className="text-xs">{error.stack}</pre>
      </div>
    );
  } else {
    return <h1>Unknown Error</h1>;
  }
};

export function App() {
  useHealthcheck();

  const devCycleReady = useIsDevCycleInitialized();

  if (!devCycleReady) {
    return <p>Loading Devcycle...</p>;
  }

  return (
    <AuthProvider>
      <Outlet />
    </AuthProvider>
  );
}

export function HydrateFallback() {
  return <p>Hydrating...</p>;
}

export default withDevCycleProvider({ sdkKey: import.meta.env.VITE_DEVCYCLE_SDK_KEY })(App);
