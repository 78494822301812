import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { toast } from "sonner";

import { client } from "~/services/client";

export function useHealthcheck() {
  const [toastId, setToastId] = useState<string | number | null>(null);

  const data = useQuery({
    queryKey: ["healthcheck"],
    queryFn: () => client.healthcheck(),
    refetchInterval: 1000 * 60 * 1,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    // If there is an error, show a toast
    if (data.isError) {
      if (toastId) {
        toast.error("Error connecting to the server", { id: toastId, duration: Infinity, closeButton: true });
        return;
      }
      const id = toast.error("Error connecting to the server", { duration: Infinity, closeButton: true });
      setToastId(() => id);
      return;
    }

    // If the connection is restored, change the toast to success
    if (data.isSuccess && toastId !== null) {
      toast.success("Connection restored", { id: toastId, duration: 3000 });
    }
  }, [data.isError, data.isSuccess]);

  return data;
}
